<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">线下签到管理</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-select v-model="attTypeVal" clearable placeholder="请选择参会人员类型" @change="typeChange(attTypeVal)">
              <el-option v-for="item in participateTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-select v-model="attConVal" clearable placeholder="是否确认参会" @change="attconfChange(attConVal)">
              <el-option v-for="item in attconferOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-input v-model="attenName" clearable placeholder="请输入姓名" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-input v-model="attenPhone" clearable placeholder="请输入手机号" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-button type="success" v-if="exportCilck" :loading="exportCilckTwo" @click="exportSignInDetail" :disabled="haveAuth"><i class="el-icon-download">导出线下签到表</i></el-button>
          </div>
        </div>

        <el-table
            ref="multipleTable"
            :data="tableData" border center
            tooltip-effect="dark"
            :row-key="row=>row.id"
            style="width: 100%"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              type="selection"
              :reserve-selection="true"
              width="55"  align="center">
          </el-table-column>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="workUnit" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="duties" label="职务" align="center">
          </el-table-column>
          <el-table-column prop="participateName" label="参会人类型" align="center">
          </el-table-column>
          <el-table-column prop="isConference" label="是否确认参会" align="center">
            <!--            0 未参会 1已参会-->
            <template slot-scope="scope">
              <span v-if="scope.row.isConference == 0">待确认</span>
              <span v-if="scope.row.isConference == 1">确认</span>
              <span v-if="scope.row.isConference == 2">拒绝</span>
            </template>
          </el-table-column>
          <el-table-column prop="dockingPersonName" label="对接人" align="center">
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      multipleSelection:[],
      value: true,
      novalue: false,
      btnText: "添加",
      btnSubText: "提交",
      addName: "添加参会人员",
      addStay: "维护住宿信息",
      addCar: "维护车辆信息",
      addDock: "维护对接人住宿信息",
      reayName: "",
      addDepClick: true,
      addDepClickKing: false,
      addStayClick: true,
      addStayClickKing: false,
      addCarClick: true,
      addCarClickKing: false,
      addDockClick: true,
      addDockClickKing: false,
      AddDateModel: {
        name: '',
        phone:'',
        workUnit:'',
        duties:'',
        idCard:'',
        sex:'',
        email:'',
        isConference:'',
        status:'',
        participateType:'',
        dockingPerson:'',
        remark:''
      },
      AddCarModel:{
        iCar:'',
        incomingInformation:'',
        trafficDepartureTime:'',
        trafficArrivalTime:'',
        returnInformation:'',
        returnDepartureTime:'',
        returnArrivalTime:'',
        returnTicket:'',
        comeTicket:'',
        carNumber:''
      },
      AddStayModel:{
        iAccommodation: '',
        checkInTime:'',
        checkOutTime:''
      },
      AddDockModel:{
        dockingPersonIn: '',
        dockingPersonOut:''
      },
      typeOptions: [
        {
          value: "0",
          label: "参会人员",
        },
        {
          value: "1",
          label: "普通嘉宾",
        },
        {
          value: "2",
          label: "演讲嘉宾",
        }
      ],
      AddDateRules: {
        name: [
          { required: true, message: "请填写参会人姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        workUnit: [
          { required: true, message: "请填写单位", trigger: "blur" },
        ],
        duties: [
          { required: true, message: "请填写职务", trigger: "blur" },
        ],
        participateType: [
          { required: true, message: "请选择参会人类型", trigger: "blur" },
        ],
        dockingPerson: [
          { required: true, message: "请选择对接人", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请填写身份证号", trigger: "blur" },
        ],
        sex: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        email: [
          { required: false},
        ],
        remark: [
          { required: false},
        ],
        isConference: [
          { required: true, message: "请选择是否确认参会", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择联系进度", trigger: "change" },
        ],
      },
      AddStayRules:{
        iAccommodation: [
          { required: true, message: "请选择是否需要住宿", trigger: "blur" },
        ]
      },
      AddCarRules:{
        iCar: [
          { required: true, message: "请选择交通方式", trigger: "blur" },
        ]
      },
      AdddialogVisible: false,
      AddStayVisible: false,
      AddCarVisible: false,
      AddDockVisible: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      perId:'',
      attenName:"",
      attenPhone:"",
      attStatuOptions:[{name:'未联系',id:0},{name:'已联系',id:1}],
      attconferOptions:[{name:'待确认',id:0},{name:'确认',id:1},{name:'拒绝',id:2}],
      participateTypeOptions:[{name:'参会人员',id:0},{name:'普通嘉宾',id:1},{name:'演讲嘉宾',id:2},{name:'主持嘉宾',id:3},{name:'点评嘉宾',id:4},{name:'领奖嘉宾',id:5}],
      carTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      stayTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      dockTypeOptions:[{name:'未维护',id:0},{name:'已维护',id:1}],
      attStaVal:'',
      attConVal:'',
      attTypeVal:'',
      haveAuth:false,
      dockingOptions:[],
      stayShow:false,
      carShow:false,
      carNumShow :false,
      carTypeVal:'',
      stayTypeVal:'',
      dockTypeVal:'',
      detailId:'',
      exportCilck:true,
      exportCilckTwo:false
    };
  },
  created() {
    this.pageList();
  },
  methods: {
    handleSelectionChange(val) {
      if(val.length>0){
        this.multipleSelection=[]
        for(let i=0;i<val.length;i++){
          this.multipleSelection.push(val[i].id);
        }
      }else{
        this.multipleSelection = [];
      }
    },
    changeStay(val){
      if(val=='1'){
        this.stayShow=true
      }else{
        this.stayShow=false
      }
    },
    changeCar(val){
      if(val=='0'){
        this.carShow=false;
        this.carNumShow = false;
      }
      else if (val == '3') {
        this.carNumShow = true;
        this.carShow=false;
      }
      else{
        this.carShow=true;
        this.carNumShow = false;
      }
    },
    searchName(){
      this.pageNum = 1;
      this.pageList();
    },
     statChange(val){
       this.pageNum = 1;
       this.attStaVal=val
       this.pageList();
     } ,
     attconfChange(val){
       this.pageNum = 1;
       this.attConVal=val
       this.pageList();
     },
    typeChange(val){
      this.pageNum = 1;
       this.attTypeVal=val
       this.pageList();
     },
    carTypeChange(val){
      this.pageNum = 1;
      this.carTypeVal=val
      this.pageList();
    },
    stayTypeChange(val){
      this.pageNum = 1;
      this.stayTypeVal=val
      this.pageList();
    },
    dockTypeChange(val){
      this.pageNum = 1;
      this.dockTypeVal=val
      this.pageList();
    },
    // 根据会议id查询对接人列表
    async findDockingPersonList() {
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId')
      });
      let { data: res } = await this.$axios.dockingPersonList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.dockingOptions = res.data;
      }
    },
    async statusChange(row){
      let data = _qs.stringify({
        id:row.id,
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        name: row.name,//参会人姓名
        phone:row.phone,//手机号
        workUnit:row.workUnit,//单位
        duties:row.duties,//职务
        idCard:row.idCard,//身份证号
        sex:row.sex,//性别
        email:row.email,//邮箱
        isConference:row.isConference,//是否确认参会 0 未参会 1已参会
        status:row.status,//联系进度 0 未联系 1已联系
        dockingPerson:row.dockingPerson,//对接人
        participateType:row.participateType,//参会人类型
        remark:row.remark//备注
      });
      let { data: res } = await this.$axios.updaAttenMans(data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.pageList();
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async confeChange(row){
        let data = _qs.stringify({
          id:row.id,
          conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
          name: row.name,//参会人姓名
          phone:row.phone,//手机号
          workUnit:row.workUnit,//单位
          duties:row.duties,//职务
          idCard:row.idCard,//身份证号
          sex:row.sex,//性别
          email:row.email,//邮箱
          isConference:row.isConference,//是否确认参会 0 未参会 1已参会
          status:row.status,//联系进度 0 未联系 1已联系
          dockingPerson:row.dockingPerson,//对接人
          participateType:row.participateType,//参会人类型
          remark:row.remark//备注
        });
        let { data: res } = await this.$axios.updaAttenMans(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.pageList();
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
    },
    async pageList() {
      let data = _qs.stringify({
      page: this.pageNum, //页数
      size: this.pageSize, //每页显示条数
      name:this.attenName,//参会人姓名
      phone:this.attenPhone,//手机号
      status:this.attStaVal,//联系进度 0未联系 1已联系
      conferenceId:window.sessionStorage.getItem('conferenceId'),
      isConference:this.attConVal,//是否确认参会 0待确认 1确认
      participateType:this.attTypeVal, //参会人员类型 0参会人员 1普通嘉宾 2演讲嘉宾
      iCar:this.carTypeVal,
      iAccommodation:this.stayTypeVal,
      iDock:this.dockTypeVal
    });
      let { data: res } = await this.$axios.pageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.pageList.records;
        this.total = res.data.pageList.total;
        if(res.data.haveAuth=='1'){
          this.haveAuth=false
        }else if(res.data.haveAuth=='0'){
          this.haveAuth=true
        }

      }
    },
    // 科室管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.pageList();
    },
    // 导出线下签到表
    async exportSignInDetail(){
      if(!this.multipleSelection || this.multipleSelection.length === 0){
        return this.$message({
          message: '请勾选签到表中的成员！',
          type: "warning",
        });
      }


      this.exportCilck = false;
      this.exportCilckTwo = true;
      let data = _qs.stringify({
        ids:this.multipleSelection.join(',')
      });

      //跳转并携带对象参数
      await this.$axios.exportSignInDetail(data,'参会人员签到表.docx');

      this.exportCilck = true;
      this.exportCilckTwo = false;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}
.el-pagination{
  margin-top: 20px;
  text-align: center;
}

/deep/ .el-input__inner{
  width: 220px;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}

/deep/ .el-button.el-button--primary.is-disabled{
  color: #fff!important;
  background-color: #a0cfff!important;
  border-color: #a0cfff!important;
}
</style>
